import { useSanaTexts } from 'components/sanaText';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { makeSimpleText } from 'utils/render';
import { ConditionalWrap } from 'components/primitives/conditionalWrap';
import Tooltip from 'components/primitives/tooltip';
import styles from './Product.module.scss';
import StockIndicator from './StockIndicator';

const IndividualStockIndicator = ({ inventory, stockLevels, availabilityDate }) => {
  const [inStockTooltip, lowStockTooltip, outOfStockTooltip] =
    useSanaTexts([
      'StockText_InStock_Tooltip',
      'StockText_LowStock_Tooltip',
      'StockText_OutOfStock_Tooltip',
    ], makeSimpleText).texts;

  if (inventory === null)
    return null;

  const { outOfStock, lowStock } = stockLevels;

  let stockIndicator, stockIndicatorTooltip;
  if (inventory > lowStock && !availabilityDate) {
    stockIndicator = styles.inStock;
    stockIndicatorTooltip = inStockTooltip;
  }
  else if (inventory <= outOfStock) {
    stockIndicator = styles.outStock;
    stockIndicatorTooltip = outOfStockTooltip;
  }
  else {
    stockIndicator = styles.lowStock;
    stockIndicatorTooltip = lowStockTooltip;
  }

  const showTooltip = !!stockIndicatorTooltip && !availabilityDate;
  return (
    <ConditionalWrap condition={showTooltip} wrapWith={children => (
      <Tooltip body={stockIndicatorTooltip} sign={false} showOnTop showOnRight>
        {children}
      </Tooltip>
    )}
    >
      <span className={stockIndicator}>
        <StockIndicator />
      </span>
    </ConditionalWrap>

  );
};

IndividualStockIndicator.propTypes = {
  inventory: PropTypes.number,
  stockLevels: PropTypes.shape({
    outOfStock: PropTypes.number.isRequired,
    lowStock: PropTypes.number.isRequired,
  }),
  availabilityDate: PropTypes.string,
};

export default memo(IndividualStockIndicator);