import type { Epic } from 'behavior/types';
import { switchMap, pluck, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { REQUEST_SAMPLE_PRODUCTS, RequestSampleProductsActions, sampleProductsReceived } from './actions';
import { requestSampleProductsMutation } from './queries';

const epic: Epic<RequestSampleProductsActions> = (action$, _, { api }) => {

  return action$.pipe(
    ofType(REQUEST_SAMPLE_PRODUCTS),
    pluck('payload', 'input'),
    switchMap(input => api.graphApi(requestSampleProductsMutation, { input }).pipe(
      pluck('sampleProducts', 'request'),
      map(request => sampleProductsReceived(request),
      )),
    ));
};

export default epic;