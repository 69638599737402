import styles from 'components/objects/product/Details.module.scss';
import useEditableSpecifications from './useEditableSpecifications';
import { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeRichText } from 'utils/render';

const ProductSpecifications = ({ specifications }) => {
  if (!specifications || !specifications.length)
    return null;
  
  return (
    <dl className={styles.tableList}>
      {useEditableSpecifications(specifications).map(({ name, value }, index) => (
        <Fragment key={index}>
          <dt className={styles.name}>{name}</dt>
          <dd className={styles.value}>{makeRichText(value.join('<br />'))}</dd>
        </Fragment>
      ))}
    </dl>
  );
};

ProductSpecifications.propTypes = {
  specifications: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
  })),
};

export default memo(ProductSpecifications);