exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "#productListPage .StockIndicator_stock-indicator-container,#searchPage .StockIndicator_stock-indicator-container{display:flex;justify-content:center}#productListPage .StockIndicator_stock-indicator-container .StockIndicator_stock-info,#searchPage .StockIndicator_stock-indicator-container .StockIndicator_stock-info{padding-left:.5em}#modals .StockIndicator_stock-indicator-container{display:block;margin-top:5px}.StockIndicator_stock-info-no-date{color:var(--stockAmount_OutOfStock_FontColor,#c00)}.StockIndicator_stock-info-date{color:var(--stockAmount_InStock_FontColor,#3cb300)}.StockIndicator_stock-icon-container{display:inline-block;height:20px;vertical-align:text-bottom}@media not all and (min-width:var(--breakpoints_Small,600)px){html:not(.print) #productListPage .StockIndicator_stock-indicator-container,html:not(.print) #searchPage .StockIndicator_stock-indicator-container{display:block;border-left:none;padding-left:0}}", ""]);

// exports
exports.locals = {
	"stock-indicator-container": "StockIndicator_stock-indicator-container",
	"stockIndicatorContainer": "StockIndicator_stock-indicator-container",
	"stock-info": "StockIndicator_stock-info",
	"stockInfo": "StockIndicator_stock-info",
	"stock-info-no-date": "StockIndicator_stock-info-no-date",
	"stockInfoNoDate": "StockIndicator_stock-info-no-date",
	"stock-info-date": "StockIndicator_stock-info-date",
	"stockInfoDate": "StockIndicator_stock-info-date",
	"stock-icon-container": "StockIndicator_stock-icon-container",
	"stockIconContainer": "StockIndicator_stock-icon-container"
};