import styles from './OrderBox.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import ViewProductButton from './b2c/ViewProductButton';
import { Placeholder } from 'components/primitives/placeholders';
import { memo } from 'react';

const ViewProductGroupButton = ({ product }) => {
  const btnPlaceholder = <Placeholder className={styles.btnPlaceholder} />;
  return (
    <>
      <ViewProductButton
        textKey="ViewProductGroup"
        className={`${btnStyles.btnSmall} btn-action`}
        product={product}
        placeholder={btnPlaceholder}
      />
    </>
  );
};

ViewProductGroupButton.propTypes = {
  product: PropTypes.object.isRequired,
};

export default memo(ViewProductGroupButton);