import styles from '../PLP.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo } from 'react';
import { SimpleText } from 'components/sanaText';
import { clearLastViewedProducts } from 'behavior/products/lastViewedTracking';
import { navigateTo } from 'behavior/events';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { routesBuilder } from 'routes';
import { createUrl } from 'behavior/routing';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const ClearButton = () => {
  const dispatch = useDispatch();
  const redirectTo = useSelector(redirectToSelector, shallowEqual);

  const clear = useHandlerLockerInPreview(() => {
    dispatch(clearLastViewedProducts());

    if (redirectTo)
      dispatch(navigateTo(redirectTo.routeData, redirectTo.url));
    else
      dispatch(navigateTo(routesBuilder.forHome()));
  });

  return (
    <button className={`${linkStyles.link} ${styles.clearViewed}`} onClick={clear}>
      <SimpleText textKey="LastViewedProducts_ClearLinkText" />
    </button>
  );
};

export default memo(ClearButton);

function redirectToSelector({ routing: { previous }, page: { backTo } }) {
  if (backTo)
    return backTo;

  if (!previous)
    return null;

    return {
      url: createUrl(previous.location),
      routeData: {
        ...previous.routeData,
        options: { restoreScroll: true },
      },
    };
}