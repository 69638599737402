import styles from 'components/objects/product/Details.module.scss';
import { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { connect } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { useProductContext } from './hooks';
import { QuantityTextBox, UomSelector, useCanViewUom } from 'components/primitives/product';
import { Placeholder } from 'components/primitives/placeholders';
import QuantityContext from './QuantityContext';
import { getNumberDecimalsSeparator } from 'utils/format';

const QuantityBox = ({ allowUOMSelection, culture, disabled }) => {
  const { product, variantId, calculatedInfo: { isOrderable }, updateUomId, uomId } = useProductContext();
  const { uoms, id, variants } = product || {};

  const canViewUom = useCanViewUom();
  const { quantityModel, updateQuantity, quantity } = useContext(QuantityContext);

  const getStandardLength = useCallback(() => variants.find(v => v.id === variantId).standardLength, [variantId]);
  const onUomChange = useCallback(uomId => {
    updateUomId(uomId);
  }, [updateUomId]);

  if (!product)
    return null;

  const showUnitOfMeasureSelector = canViewUom && uoms && allowUOMSelection && uoms.length > 1;
  if (!isOrderable && !showUnitOfMeasureSelector)
    return null;

  const showStandardLength = !showUnitOfMeasureSelector && variantId;

  const quantityTextPlaceholder = <Placeholder className={styles.quantityTextPlaceholder} />;
  const separator = getNumberDecimalsSeparator(culture);

  return (
    <Row className={styles.row} crossAxisAlign="center">
      <Col xs={12} sm={3} lg={2} className={styles.quantityBoxCol}>
        <label className={styles.ellipsis} htmlFor={quantityModel ? 'quantity_Input' : null}>
          <SimpleText textKey="Quantity" placeholder={quantityTextPlaceholder} />
        </label>
      </Col>
      {(quantityModel || showUnitOfMeasureSelector) &&
        <Col xs={12} sm={9} lg={10} className={`${styles.quantityBoxCol} ${styles.actionsCol}`}>
          {quantityModel &&
            <QuantityTextBox
              className={styles.quantity}
              quantity={quantityModel}
              value={quantity && quantity.value}
              onChange={updateQuantity}
              separator={separator}
              disabled={disabled}
            />
          }
          {showUnitOfMeasureSelector &&
            <UomSelector
              className={styles.uom}
              productId={id}
              allowUOMSelection={allowUOMSelection}
              uomId={uomId}
              uoms={uoms}
              onUomChange={onUomChange}
              isOrderable={isOrderable}
            />
          }
          {showStandardLength && <SimpleText textKey="StandardLength" formatWith={[getStandardLength()]} />}
        </Col>
      }
    </Row>
  );
};

QuantityBox.propTypes = {
  allowUOMSelection: PropTypes.bool.isRequired,
  culture: PropTypes.string,
  disabled: PropTypes.bool,
};

export default connect(
  ({ settings, localization }) => ({
    allowUOMSelection: settings.product
      && settings.product.allowUOMSelection,
    culture: localization.currentLanguage && localization.currentLanguage.cultureName,
  }),
)(QuantityBox);