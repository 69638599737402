import styles from './PLP.module.scss';
import { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import ProductContext from './ProductContext';
import { makeRichText } from 'utils/render';
import { stripFormatting } from 'utils/helpers';
import useEditableSpecifications from 'components/objects/product/tabs/useEditableSpecifications';

const Specifications = ({ specifications }) => {
  if (!specifications || !specifications.length)
    return null;

  const processedFields = useEditableSpecifications(specifications);

  return (
    <div className={styles.productAttributes}>
      {processedFields.map((specification, index) => (
        <Fragment key={index}>
          <span>
            {makeRichText(stripFormatting(specification.value.join(', ').replace(/<br\s*\/?>/, '')))}
            {index !== specifications.length - 1 && (
              <br />
            )}
          </span>
          {' '}
        </Fragment>
      ))}
    </div>
  );
};

Specifications.propTypes = {
  specifications: PropTypes.arrayOf(PropTypes.shape({
    rawValue: PropTypes.string,
      value: PropTypes.arrayOf(PropTypes.string),
  })),
};

export default connectToContext([ProductContext], ({ specifications }) => ({ specifications }))(
  memo(Specifications),
);