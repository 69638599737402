import { SAMPLE_PRODUCTS_RECEIVED } from './actions';
import { createReducer } from 'utils/redux';

const initialPageState = undefined;

export default createReducer(initialPageState, {
  [SAMPLE_PRODUCTS_RECEIVED]: onSampleProductsReceived,
});

function onSampleProductsReceived(state, action) {
  return { ...state, isRequestSampleSuccess: action.payload };
}