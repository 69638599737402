import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import { switchMap, pluck, map, withLatestFrom } from 'rxjs/operators';
import { retryWithToast } from 'behavior/errorHandling';
import { routesBuilder } from 'routes';
import {
  PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED,
  PRODUCT_SELECTOR_PRODUCT_REQUESTED,
  receiveSearchSuggestions,
  receiveProduct,
} from './actions';
import { productSearchSuggestionsQuery, productQuery } from './queries';

export default (action$, state$, { api, console }) => {
  const searchProducts$ = action$.pipe(
    ofType(PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED),
    pluck('payload'),
    switchMap(({ keywords, count }) => api.graphApi(productSearchSuggestionsQuery, { options: { keywords, count } }).pipe(
      pluck('catalog', 'quickSearch', 'products'),
      map(receiveSearchSuggestions),
      retryWithToast(action$, console),
    )),
  );

  const requestProduct$ = action$.pipe(
    ofType(PRODUCT_SELECTOR_PRODUCT_REQUESTED),
    pluck('payload'),
    withLatestFrom(state$),
    map(([{ id }, { analytics }]) => ({
      options: { ids: [id], shouldGroupDocuments: false },
      loadCategories: analytics && analytics.isTrackingEnabled,
    })),
    switchMap(params => api.graphApi(productQuery, params).pipe(
      pluck('catalog', 'products', 'products'),
      map(products => {
        const product = products[0];
        product && (product.routeData = routesBuilder.forProduct(product.id));
        return receiveProduct(product);
      }),
      retryWithToast(action$, console),
    )),
  );

  return merge(searchProducts$, requestProduct$);
};