import styles from './StockIndicator.module.scss';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { toLocaleDate, isDateMaxValue } from 'utils/format';
import { makeSimpleText } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';

const StockAvailabilityDate = ({ inventory, stockLevels, availabilityDate, skipInventoryCheck = false, forBasketPage = false }) => {
  const culture = useSelector(state => state.localization.currentLanguage && state.localization.currentLanguage.cultureName);
  const { texts: [
    noAvailabilityDate,
    earliestAvailabilityDate,
  ], loaded } = useSanaTexts([
    `${forBasketPage ? 'BasketPage_' : ''}StockText_NoAvailabilityDate`,
    'StockText_EarliestAvailabilityDate',
  ]);

  if (!loaded)
    return null;

  if (inventory === null && !skipInventoryCheck)
    return null;

  const { outOfStock } = !skipInventoryCheck && stockLevels;
  const showAvailabilityDate = availabilityDate && !isDateMaxValue(availabilityDate);
  const showNoFutureStockMessage = availabilityDate && isDateMaxValue(availabilityDate) && (skipInventoryCheck || inventory <= outOfStock);

  return (
    <>
      {showAvailabilityDate && (
        <div className={`${styles.stockInfoDate} ${styles.stockInfo}`}>
          {makeSimpleText(earliestAvailabilityDate, [toLocaleDate(availabilityDate, culture)])}
        </div>
      )}
      {showNoFutureStockMessage && (
        <div className={`${styles.stockInfoNoDate} ${styles.stockInfo}`}>
          {makeSimpleText(noAvailabilityDate.includes('[STOCKAMOUNT]') ? noAvailabilityDate.replace('[STOCKAMOUNT]', inventory) : noAvailabilityDate)}
        </div>
      )}
    </>
  );
};

StockAvailabilityDate.propTypes = {
  inventory: PropTypes.number,
  stockLevels: PropTypes.shape({
    outOfStock: PropTypes.number.isRequired,
    lowStock: PropTypes.number.isRequired,
  }),
  availabilityDate: PropTypes.string,
  skipInventoryCheck: PropTypes.bool,
  forBasketPage: PropTypes.bool,
};

export default memo(StockAvailabilityDate);