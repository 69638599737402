import PropTypes from 'prop-types';
import { memo, useMemo, useState } from 'react';
import { DatePicker as DateSelector } from 'components/primitives/form';
import { useSelector } from 'react-redux';
import DateOnly from 'date-only';
import { isDateMaxValue } from 'utils/format';

const RequestedDeliveryDate = ({ requestedDeliveryDate, onChange, getCurrentRequestedDeliveryDate, availabilityDate }) => {
  const currentValue = getCurrentRequestedDeliveryDate();
  const currentDate = useSelector(s => s.settings.basket.adjustedDateTime);
  const [value, setValue] = useState(currentValue || requestedDeliveryDate);

  const handleChange = value => {
    onChange(value);
    setValue(value);
  };
  const minDate = useMemo(() => {
    if (!availabilityDate || isDateMaxValue(availabilityDate))
      return currentDate ? DateOnly.parse(currentDate) : DateOnly.today();

    return DateOnly.parse(availabilityDate);
  }, [availabilityDate, currentDate]);

  return (
    <DateSelector
      onChange={handleChange}
      value={value}
      minDate={minDate}
      defaultActiveStartDate={minDate}
    />
  );
};

RequestedDeliveryDate.propTypes = {
  requestedDeliveryDate: PropTypes.string,
  onChange: PropTypes.func,
  getCurrentRequestedDeliveryDate: PropTypes.func,
  availabilityDate: PropTypes.string,
};

export default memo(RequestedDeliveryDate);
