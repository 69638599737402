import styles from './ChildImages.module.scss';
import detailsStyles from 'components/objects/product/Details.module.scss';
import { Col, Row } from 'components/primitives/grid';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { updateProductGeneralInfo } from 'behavior/pages/product';
import { connect, useDispatch } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import PropTypes from 'prop-types';
import { ProductSpecificationFilter } from 'behavior/products/product';
import { useProductContext } from '../hooks';
import { usePrintMode, useQueryString } from 'utils/hooks';
import Tooltip from 'components/primitives/tooltip';
import { ConditionalWrap } from 'components/primitives/conditionalWrap';

const ChildImages = ({ productId, imagesList, analytics, isGeneralInfoLoading, noImageSmallUrl }) => {
  if (!imagesList.length)
    return null;

  const queryString = useQueryString(true);
  const isPrintMode = usePrintMode();
  const queryChildId = queryString && queryString.values.childId;
  const dispatch = useDispatch();
  const { childId, updateContext } = useProductContext();
  let selectedChildId;
  //For the print mode we need to detect selected child id using query param
  if (isPrintMode) {
    selectedChildId = !!queryChildId ? queryChildId : productId;
  }
  else {
    selectedChildId = !!childId ? childId : productId;
  }

  const selectedImageIndex = useMemo(() =>
    imagesList.findIndex(item => item.id === selectedChildId),
    [productId, imagesList, selectedChildId]);
  const [activeIndex, setActiveIndex] = useState(selectedImageIndex);

  const colorPlaceholder = <Placeholder className={styles.childImagesPlaceholder} />;

  const goTo = index => {
    if (index === activeIndex)
      return;
    if (index < 0 || index >= imagesList.length)
      return;
    setActiveIndex(index);
  };

  useEffect(() => {
    if (!!selectedChildId && selectedChildId !== productId) {
      updateProducInfo(selectedChildId);
    }
    goTo(selectedImageIndex);
  }, [productId, selectedChildId]);

  const updateProducInfo = useCallback(
    productId => {
      const options = {
        productId,
        specificationFilter: ProductSpecificationFilter.ForDetails,
        loadRelatedProductsCategories: analytics && analytics.isTrackingEnabled,
      };
      dispatch(updateProductGeneralInfo(options));
      updateContext({ childId: productId });
    },
    [productId, selectedChildId],
  );

  const handleImageClick = index => {
    const productId = imagesList[index].id;
    updateProducInfo(productId);
    goTo(index);
  };

  return (
    <Row crossAxisAlign="center">
      <Col xs={3} lg={isPrintMode ? 12 : 2} className={detailsStyles.ellipsis} id="childImages_Label">
        <SimpleText textKey="ChildImagesLabel" placeholder={colorPlaceholder} />
      </Col>
      <Col xs={9} lg={isPrintMode ? 12 : 10} className={detailsStyles.limitedWidth}>
        <div className={styles.childImages}>
          {imagesList.map((item, index) => (
            <ConditionalWrap key={index} condition={!!item.color} wrapWith={children => (
              <Tooltip body={item.color} sign={false} showOnTop className={styles.tooltip}>
                {children}
              </Tooltip>
            )}
            >
              <button
                disabled={isGeneralInfoLoading && 'disabled'}
                className={`${styles.childImage} ${activeIndex === index ? styles.active : ''}`}
                key={index}
                onClick={() => handleImageClick(index)}
              >
                <img alt="" src={item.image ? item.image.small : noImageSmallUrl} />
              </button>
              {isPrintMode && (
                <div className={styles.colorCode}>{item.color}</div>
              )}
            </ConditionalWrap>
          ),
          )
          }
        </div>
      </Col>
    </Row>
  );
};

ChildImages.propTypes = {
  productId: PropTypes.string,
  imagesList: PropTypes.array,
  analytics: PropTypes.object,
  isGeneralInfoLoading: PropTypes.bool,
  noImageSmallUrl: PropTypes.string,
};

const mapStateToProps = ({
  page: { product: { id, childImages, isGeneralInfoLoading } },
  settings: { product: { noImage: { small } } },
  analytics,
}) => ({
  productId: id,
  imagesList: childImages,
  analytics,
  isGeneralInfoLoading,
  noImageSmallUrl: small,
});

export default connect(mapStateToProps)(ChildImages);