import styles from './Basket.module.scss';
import { useSanaTexts } from 'components/sanaText';
import { WarningAlert } from 'components/primitives/alerts';
import { makeRichText } from 'utils/render';
import { useSelector } from 'react-redux';

const MinimumOrderAmountWarning = () => {
  const { texts: [minimumOrderAmountMsg] }
    = useSanaTexts(['MinimumOrderAmount_WarningMessage']);

  const minimumOrderAmount = useSelector(state => state.settings.basket.minimumOrderAmount);

  return (
    <WarningAlert className={styles.recalcMsg}>
      {makeRichText(minimumOrderAmountMsg, [minimumOrderAmount])}
    </WarningAlert>
  );
};

export default MinimumOrderAmountWarning;
