import styles from '../Lines.module.scss';
import LineTemplatePropTypes from './LineTemplatePropTypes';
import { SimpleText } from 'components/sanaText';
import { useUpdateContext } from '../../updateContext';
import { Placeholder } from 'components/primitives/placeholders';

const LineTemplate = ({
  productInfo,
  agreementTerm,
  productActionBlock,
  actionLinks,
  quantityBox,
  uomLabel,
  priceNode,
  productDiscount,
  subTotal,
  className = null,
  isGroupedLine,
  id,
  totalLength,
  requestedDeliveryDateBox,
}) => {
  const { isB2BPreset } = useUpdateContext();
  const tableHeadingPlaceholder = <Placeholder className={styles.tableHeadingPlaceholder} />;

  let quantityColSpan = 1;
  if (!uomLabel) {
    quantityColSpan++;
    if (!priceNode)
      quantityColSpan++;
  }

  return (
    <tbody className={className} id={id}>
      <tr className={styles.productBlock}>
        {(isB2BPreset || productActionBlock) && (
          <td className={styles.productActionCell} aria-hidden={!isGroupedLine}>
            {productActionBlock}
          </td>
        )}
        <td className={styles.productInfoCell} colSpan={productActionBlock ? '2' : '3'} headers="basketProduct" aria-describedby="basketProduct">
          {productInfo}
          {agreementTerm}
          {totalLength &&
            <div className={styles.piecesCell} aria-describedby="totalLength">
              <SimpleText textKey="NumberOfPieces" placeholder={tableHeadingPlaceholder} />: <span>{totalLength}</span>
            </div>
          }
          {requestedDeliveryDateBox && <div>{requestedDeliveryDateBox}</div>}
        </td>
      </tr>
      <tr className={`${styles.priceBlock} ${priceNode ? '' : styles.noPrice}`}>
        <td className={styles.quantityCell} colSpan={quantityColSpan} headers="basketQuantity" aria-describedby="basketQuantity">
          {quantityBox}
        </td>
        {uomLabel &&
          <td className={styles.uomCell} colSpan={priceNode ? null : '2'} headers="basketUom" aria-describedby="basketUom">
            {uomLabel}
          </td>
        }
        {priceNode && (
          <td className={styles.costCell} headers="basketPrice" aria-describedby="basketPrice">
            {priceNode}
          </td>
        )}
      </tr>
      <tr className={`${styles.rowTotal} ${productDiscount ? styles.noBorder : styles.bottomIndent} ${subTotal ? '' : styles.noPrice}`}>
        <td colSpan={subTotal ? '2' : '3'} headers="basketProduct" aria-describedby="basketProduct">
          {actionLinks}
        </td>
        {subTotal && (
          <td className={styles.costCell} headers="basketTotal" aria-describedby="basketTotal">
            <span className={styles.totalLabel}><SimpleText textKey="Total_Label" /></span> {subTotal}
          </td>
        )}
      </tr>
      {productDiscount && (
        <tr className={styles.rowDiscount}>
          <td colSpan="3" headers="basketTotal" aria-describedby="basketTotal">
            {productDiscount}
          </td>
        </tr>
      )}
    </tbody>
  );
};

LineTemplate.propTypes = LineTemplatePropTypes;

export default LineTemplate;