import styles from '../PLP.module.scss';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { Select } from 'components/primitives/form';

const VariantDropdownRow = ({ id, value, variantComponents, onChange }) => {
  const items = variantComponents.map(c => ({ name: c.name, value: c.id }));
  const labelId = id + '_lb';

  return (
    <Row className={styles.row} crossAxisAlign="center">
      <Col xs={12}>
        <Select
          items={items}
          onChange={onChange}
          value={value}
          id={id}
          labelId={labelId}
          className={styles.variant}
          optionClassName={styles.variantOption}
        />
      </Col>
    </Row>
  );
};

VariantDropdownRow.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  variantComponents: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

export default VariantDropdownRow;