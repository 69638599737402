import { memo } from 'react';
import styles from './StockIndicator.module.scss';
import StockIcon from 'components/primitives/icons/Stock';

const StockIndicator = () => {
  return (
    <div className={styles.stockIconContainer}>
      <StockIcon />
    </div>
  );
};

export default memo(StockIndicator);