import { memo } from 'react';
import { useProductContext } from './hooks';
import styles from './Details.module.scss';
import Tooltip from 'components/primitives/tooltip';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { ConditionalWrap } from 'components/primitives/conditionalWrap';
import PropTypes from 'prop-types';

const CareLabels = careLabels => {
    const { product } = useProductContext();
    const { careLabelInfos } = product || careLabels || {};

    if (!careLabelInfos || !careLabelInfos.length)
        return null;

    return (
        <div className={styles.productCareLabels}>
            {careLabelInfos.map(item => {
                const titleValue = useSanaTexts([`CareLabel_${item.name}`], makeSimpleText).texts;
                const child = <img src={item.imageUrl} alt={item.name} />;
                return (
                    <div key={item.name}>
                        <ConditionalWrap condition={!!titleValue[0]} wrapWith={children => (
                            <Tooltip body={titleValue} sign={false} showOnTop showOnRight>
                                {children}
                            </Tooltip>
                        )}
                        >
                            {child}
                        </ConditionalWrap>
                    </div>
                );
            })}
        </div>
    );
};

CareLabels.propTypes = {
    careLabels: PropTypes.shape({
        careLabelInfos: PropTypes.arrayOf(PropTypes.string),
    }),
};

export default memo(CareLabels);