import styles from '../PLP.module.scss';
import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import VariantDropdownRow from './VariantDropdownRow';
import ListContext from './ListContext';
import { connectToContext } from 'utils/react';

const VariantDropdown = ({ setVariantSelection, updateVariantQuantity, product }) => {
  useEffect(() => setVariantSelection(product.id, product.defaultVariantId), []);

  const handleVariantChange = variantId => {
    updateVariantQuantity(product.id, product.uom.id, variantId);
    setVariantSelection(product.id, variantId);
  };

  return (
    <fieldset className={styles.variantDropdowns}>
      {product.variantComponentGroups.map(({ id: groupId, components }) => {
        return (
          <VariantDropdownRow
            id={groupId}
            key={groupId}
            variantComponents={components}
            onChange={handleVariantChange}
            value={product.defaultVariantId}
          />
        );
      })}
    </fieldset>
  );
};

VariantDropdown.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    uoms: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ),
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
    hasVariants: PropTypes.bool.isRequired,
    variantComponentGroups: PropTypes.array.isRequired,

  }).isRequired,
  updateVariantQuantity: PropTypes.func.isRequired,
  setVariantSelection: PropTypes.func.isRequired,

};

const memoizedVariantDropdown = memo(VariantDropdown);

export default connectToContext(
  [ListContext],
  ({ setVariantSelection, updateVariantQuantity }) => ({ setVariantSelection, updateVariantQuantity }),
)(memoizedVariantDropdown);

