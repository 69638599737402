import styles from './nonOrderable/NonOrderable.module.scss';
import PropTypes from 'prop-types';
import { DangerAlert } from 'components/primitives/alerts';
import { RichText } from 'components/sanaText';
import Closable from './nonOrderable/Closable';

const ValidationWarnings = ({ warnings }) => {
  if (!warnings || warnings.length === 0)
    return null;

  return (
    <Closable>
      <DangerAlert role="alert" className={styles.block}>
        {warnings && (
          <ul className={styles.lines}>
            {warnings.map((warning, index) => (
              <li key={index}>
                <RichText textKey={warning.textKey} />
              </li>
            ))}
          </ul>
        )}
      </DangerAlert>
    </Closable>
  );
};

ValidationWarnings.propTypes = {
  warnings: PropTypes.arrayOf(PropTypes.shape({
    textKey: PropTypes.string,
  })),
};

export default ValidationWarnings;