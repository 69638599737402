import styles from './StockIndicator.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import IndividualStockIndicator from './IndividualStockIndicator';
import StockAvailabilityDate from './StockAvailabilityDate';

const IndividualStock = ({ inventory, stockLevels, availabilityDate, className = '' }) => {
  if (inventory === null)
    return null;

  return (
    <div className={`${styles.stockIndicatorContainer} ${className}`}>
      <IndividualStockIndicator inventory={inventory} stockLevels={stockLevels} availabilityDate={availabilityDate} />
      <StockAvailabilityDate inventory={inventory} stockLevels={stockLevels} availabilityDate={availabilityDate} />
    </div>
  );
};

IndividualStock.propTypes = {
  inventory: PropTypes.number,
  stockLevels: PropTypes.shape({
    outOfStock: PropTypes.number.isRequired,
    lowStock: PropTypes.number.isRequired,
  }),
  availabilityDate: PropTypes.string,
  className: PropTypes.string,
};

export default memo(IndividualStock);