import styles from './Details.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useMemo } from 'react';
import { useSimpleTexts } from 'components/sanaText';
import { connectToContext } from 'utils/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import ProductContext from './ProductContext';
import { Link } from 'components/primitives/links';
import { Placeholder } from 'components/primitives/placeholders';
import { showMatrix } from './showMatrix';
import { useCanViewUom } from 'components/primitives/product';
import { stringifyQuery, parseQuery } from 'utils/url';

const PrintPreviewLink = ({
  isPrintMode,
  pathname,
  search,
  routeData,
  variantId,
  uomId,
  className,
  product,
  matrixPreset,
  childId,
}) => {
  const canViewUom = useCanViewUom();

  const { to, url } = useMemo(() => {
    const query = parseQuery(search);
    query.print = '1';

    const showUomInUrl = canViewUom && !!product.uoms && product.uoms.length > 1 && !!uomId;
    const showVariantIdInUrl = (!matrixPreset || !showMatrix(product)) && !!variantId;

    showVariantIdInUrl && (query.variantId = variantId);
    showUomInUrl && (query.uomId = uomId);

    !!childId && (query.childId = childId);

    return {
      to: routeData,
      url: pathname + stringifyQuery(query),
    };
  }, [
    pathname,
    search,
    routeData,
    variantId,
    uomId,
    product,
    matrixPreset,
    canViewUom,
    childId,
  ]);

  const { texts: [btnText], loaded } = useSimpleTexts(['ButtonText_PrintPreview']);

  return loaded
    ? (
      <Link to={to} url={url} rel="nofollow" replaceHistory
        className={joinClasses(
          className,
          styles.link,
          styles.hypPrintPreview,
          linkStyles.link,
          linkStyles.arrowed,
          isPrintMode && styles.hidden,
        )}
        // tabIndex allows link to be focusable programmatically in Safari browser
        tabIndex="0"
      >
        {btnText}
      </Link>
    )
    : (
      <Placeholder
        className={
          joinClasses(
            styles.printPreviewPlaceholder,
            styles.link,
            isPrintMode && styles.hidden,
          )}
      />
    );
};

PrintPreviewLink.propTypes = {
  isPrintMode: PropTypes.bool,
  pathname: PropTypes.any,
  search: PropTypes.string,
  routeData: PropTypes.any,
  variantId: PropTypes.any,
  uomId: PropTypes.any,
  className: PropTypes.string,
  product: PropTypes.shape({
    uoms: PropTypes.array,
  }),
  matrixPreset: PropTypes.bool,
  childId: PropTypes.any,
};
const mapStateToProps = ({
  routing: { location: { pathname, search }, routeData },
}) => ({
  pathname,
  search,
  routeData,
});

const ConnectedPrintPreviewLink = connect(mapStateToProps)(PrintPreviewLink);

export default connectToContext(
  [ProductContext],
  ({ variantId, uomId, product, childId }) => ({
    variantId,
    uomId,
    product,
    childId,
  }),
)(ConnectedPrintPreviewLink);
