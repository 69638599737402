import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HeadInjection } from '../analytics';
import defaultIcon from './favicon.ico';
import { usePrintMode } from 'utils/hooks';
import { makeSimpleText } from 'utils/render';

const Head = ({
  title,
  description,
  index,
  faviconHref,
  externalFont,
  languageName,
  visualDesignerInitialized,
}) => {
  const isPrintMode = usePrintMode();
  const metaIndex = index ? 'index' : 'noindex';
  const htmlAttributes = { lang: languageName };

  useEffect(() => {
    document.documentElement.classList[isPrintMode ? 'add' : 'remove']('print');
  }, [isPrintMode]);

  if (visualDesignerInitialized)
    return externalFont ? <Helmet><link rel="stylesheet" href={externalFont} /></Helmet> : null;

  return (
    <>
      <HeadInjection>
        {tags => <Helmet>{tags}</Helmet>}
      </HeadInjection>
      <Helmet htmlAttributes={htmlAttributes}>
        {title && <title>{makeSimpleText(title)}</title>}
        <meta name="description" content={description || ''} />
        <meta name="robots" content={metaIndex + ',follow'} />
        {externalFont && <link rel="stylesheet" href={externalFont} />}
        {faviconHref && <link rel="shortcut icon" href={faviconHref} />}
      </Helmet>
    </>
  );
};

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  faviconHref: PropTypes.string,
  externalFont: PropTypes.string,
  languageName: PropTypes.string,
  index: PropTypes.bool,
  visualDesignerInitialized: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Head);

const EMPTY_FAVICON = 'data:;base64,iVBORw0KGgo=';

function mapStateToProps({
  page,
  theme,
  localization: {
    currentLanguage: { name },
  },
  visualDesigner: { initialized: visualDesignerInitialized } = {},
}) {
  const stateProps = {
    title: page.metaTitle,
    description: page.metaDescription,
    index: page.index,
    faviconHref: null,
    externalFont: null,
    languageName: name,
    visualDesignerInitialized,
  };

  if (theme.values) {
    stateProps.externalFont = theme.values.externalFont || null;
    stateProps.faviconHref = theme.values.favIcon || EMPTY_FAVICON;
  } else {
    stateProps.faviconHref = defaultIcon;
  }

  return stateProps;
}
