import styles from './PLP.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import ProductContext from './ProductContext';
import { ProductStock } from 'components/primitives/product';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { useSelector } from 'react-redux';
import IndividualStock from 'components/primitives/product/IndividualStock';

const Stock = ({ stockLevels, inventory, availabilityDate, hasVariants, variants, selectedVariantId }) => {
  const canViewStock = useHasAbilities(AbilityTo.ViewStock)[0];
  const isPreview = useIsPreview();
  const showIndividualStockInfo = useSelector(({ settings: { product } }) => product && product.showIndividualStockInfo);

  const selectedVariant = hasVariants && selectedVariantId && variants.find(v => v.id === selectedVariantId);
  availabilityDate = hasVariants && selectedVariant ? selectedVariant.availabilityDate : availabilityDate;
  const currentInventory = selectedVariant ? selectedVariant.inventory : inventory;

  if (!canViewStock)
    return null;

  if (currentInventory === null || stockLevels == null) {
    if (isPreview) {
      return (
        <span className={styles.stockIndication}>
          <Placeholder className={styles.stockPreviewPlaceholder} />
        </span>
      );
    }

    return null;
  }

  if (showIndividualStockInfo) {
    return <IndividualStock inventory={currentInventory} stockLevels={stockLevels} availabilityDate={availabilityDate} className={styles.stockIndicatorContainer} />;
  }

  return (
    <span className={styles.stockIndication}>
      <ProductStock inventory={inventory} stockLevels={stockLevels} />
    </span>
  );
};

Stock.propTypes = {
  inventory: PropTypes.number,
  stockLevels: PropTypes.shape({
    outOfStock: PropTypes.number.isRequired,
    lowStock: PropTypes.number.isRequired,
    maxStockNumber: PropTypes.number,
  }),
  availabilityDate: PropTypes.string,
  hasVariants: PropTypes.bool,
  variants: PropTypes.array,
  selectedVariantId: PropTypes.string,
};

export default connectToContext(
  [ProductContext],
  ({ stockLevels, inventory, availabilityDate, hasVariants, variants, selectedVariantId }) => ({
    stockLevels, inventory, availabilityDate, hasVariants, variants, selectedVariantId,
  }),
)(
  memo(Stock),
);