import {
  updateProductCalculatedFieldsQuery,
  loadProductGeneralInfoQuery,
  reviewsQuery,
  addReview,
  requestVolumePricesQuery,
  salesAgreementQuery,
} from './queries';
import {
  UPDATE_PRODUCT_CALCULATED_FIELDS,
  productCalculatedFieldsLoaded,
  UPDATE_PRODUCT_GENERAL_INFO,
  productGeneralInfoLoaded,
  REVIEWS_REQUESTED,
  reviewsReceived,
  REVIEW_SUBMITTED,
  reviewProcessed,
  VOLUME_PRICES_REQUESTED,
  volumePriceReceived,
  SALES_AGREEMENT_REQUESTED,
  receiveSalesAgreement,
} from './actions';
import { parseRelatedProducts, filterImages } from './handler';
import { switchMap, map, takeUntil, exhaustMap, pluck, filter, mergeMap, catchError, tap, startWith, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { rewriteTo } from 'behavior/routing';
import { LOCATION_CHANGED } from 'behavior/events';
import { routesBuilder } from 'routes';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';
import { EMPTY, merge, of } from 'rxjs';
import { resetCaptcha } from 'behavior/captcha';
import { unlockForm, FormLockKeys } from 'behavior/pages';
import { requestAbility } from 'behavior/user/epic';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { pluckAsPage } from '../helpers';
import { PageComponentNames } from '../componentNames';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';

const productEpic = (action$, state$, dependencies) => {
  const { api, logger } = dependencies;

  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  const onFieldsRequested$ = action$.pipe(
    ofType(UPDATE_PRODUCT_CALCULATED_FIELDS),
    switchMap(action => api.graphApi(updateProductCalculatedFieldsQuery, action.payload).pipe(
      map(mapResponseToAction),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );

  const onProductGeneralInfoRequested$ = action$.pipe(
    ofType(UPDATE_PRODUCT_GENERAL_INFO),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const query = loadProductGeneralInfoQuery({ isInsiteEditor: state.visualDesigner.insiteEditor });
      return api.graphApi(query, action.payload.options).pipe(
        pluckAsPage('product', PageComponentNames.Product),
        tap(product => parseRelatedProducts(product)),
        tap(filterImages),
        pluck('page', 'product'),
        mergeMap(product => [
          productGeneralInfoLoaded(product),
          unsetLoadingIndicator(),
        ]),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
        startWith(setLoadingIndicator()),
      );
    }),
  );

  const onReviewsRequested$ = action$.pipe(
    ofType(REVIEWS_REQUESTED),
    exhaustMap(action => api.graphApi(reviewsQuery, action.payload).pipe(
      pluck('catalog', 'products', 'products', '0', 'reviews'),
      filter(r => r && r.list && r.list.length),
      map(r => reviewsReceived(r.list)),
      takeUntil(locationChanged$),
    )),
  );

  const resetCaptchaAction = resetCaptcha();
  const reviewProcessedAction = reviewProcessed(true);
  const onReviewSubmitted$ = action$.pipe(
    ofType(REVIEW_SUBMITTED),
    exhaustMap(action => api.graphApi(addReview, { data: action.payload }).pipe(
      mergeMap(_ => [reviewProcessedAction, resetCaptchaAction, unlockForm(FormLockKeys.Review)]),
      catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptchaAction, unlockForm(FormLockKeys.Review))),
      retryWithToast(action$, logger, _ => of(unlockForm(FormLockKeys.Review))),
      takeUntil(locationChanged$),
    )),
  );

  const onVolumePricesRequested$ = action$.pipe(
    ofType(VOLUME_PRICES_REQUESTED),
    switchMap(action => api.graphApi(requestVolumePricesQuery, action.payload).pipe(
      map(data => {
        const volumePrices = data.catalog.volumePrices;
        const { variantId, uomId } = action.payload;

        return volumePriceReceived({ prices: volumePrices, variantId, uomId });
      }),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );

  const onAgreementTermsRequested$ = action$.pipe(
    ofType(SALES_AGREEMENT_REQUESTED),
    pluck('payload'),
    switchMap(({ agreementId, productId }) => api.graphApi(salesAgreementQuery, { agreementId, productIds: [productId] }).pipe(
      pluck('salesAgreements'),
      switchMap(({ agreement, linesAvailability }) => requestAbility(AbilityTo.ViewUnitOfMeasure, state$, dependencies).pipe(
        map(canViewUomAbility => {
          const { uom, uoms } = state$.value.page.product;
          return receiveSalesAgreement(
            productId,
            agreement,
            linesAvailability,
            canViewUomAbility === AbilityState.Available,
            state$.value.settings.product.allowUOMSelection,
            uom,
            uoms,
          );
        }),
      )),
      catchError(_ => {
        logger.warn('Could not retrieve sales agreement terms for the product. '
          + 'The agreement is specified in the basket but the server returned no agreement terms. The server might be in offline mode.');
        return EMPTY;
      }),
      takeUntil(locationChanged$),
    )),
  );

  return merge(
    onFieldsRequested$,
    onProductGeneralInfoRequested$,
    onReviewsRequested$,
    onReviewSubmitted$,
    onVolumePricesRequested$,
    onAgreementTermsRequested$,
  );
};

export default productEpic;

function mapResponseToAction(data) {
  const product = data.catalog.products.products[0];
  if (!product)
    return rewriteTo(routesBuilder.forNotFound());

  return productCalculatedFieldsLoaded(product);
}