import styles from './Reviews.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toasts } from 'behavior/toasts';
import { SanaForm, FormGroup, useCaptchaOnSubmit, CaptchaFormGroup, RadioFormGroup } from 'components/objects/forms/';
import { RatingField, TextBoxField, TextAreaField, EmailAddressField } from 'components/objects/forms/fields';
import ReviewsButton from './ButtonReviews';
import { RichText, SimpleText, useSimpleTexts } from 'components/sanaText';
import { sendReview } from 'behavior/pages/product';
import { connectToContext } from 'utils/react';
import ProductContext from '../ProductContext';
import { FormLockKeys } from 'behavior/pages';
import DescriptionMessage from './DescriptionMessage';

const ReviewForm = ({ productId, email, saved, sendReview }) => {
  const [
    ratingTitle,
    titleLabelText,
    descriptionTitle,
    emailTitle,
  ] = useSimpleTexts([
    'WriteReview_RatingLabel',
    'WriteReview_TitleLabel',
    'WriteReview_MessageLabel',
    'YourEmail',
  ]).texts;

  const { onSubmit, captchaRef } = useCaptchaOnSubmit(
    formData => sendReview({ productId, ...formData }),
    FormLockKeys.Review,
    [productId],
  );

  useSuccessToast(saved);

  const initialValues = { email: email || '' };

  return (
    <div className={styles.write} id="reviews_add" key={productId}>
      <div>
        <ReviewsButton total={''} className={linkStyles.arrowed} />
        <h3 className={styles.title}><SimpleText textKey="WriteReview_Title" /></h3>
      </div>
      <span><SimpleText textKey="WriteReview_Description" /></span>
      <SanaForm
        name="Review"
        onSubmit={onSubmit}
        initialValues={initialValues}
        shouldReset={saved}
        tabIndex={-1}
        onKeyDown={stopPropagation}
        className={styles.form}
      >
        <RadioFormGroup
          fieldName="rating"
          fieldTitle={ratingTitle}
          fieldComponent={RatingField}
          required
          validation={{ required: true }}
        />
        <FormGroup
          fieldName="title"
          fieldTitle={titleLabelText}
          fieldComponent={TextBoxField}
          maxLength={100}
          required
          validation={{
            required: true,
            readableName: true,
          }}
        />
        <FormGroup
          fieldName="description"
          fieldTitle={descriptionTitle}
          fieldComponent={TextAreaField}
          fieldDescriptionComponent={DescriptionMessage}
          maxLength={2000}
          required
          validation={{ required: true }}
        />
        <FormGroup
          fieldName="email"
          fieldTitle={emailTitle}
          fieldComponent={EmailAddressField}
          maxLength={80}
          required
          validation={{
            required: true,
          }}
        />
        <CaptchaFormGroup ref={captchaRef} />
        <SanaForm.SubmitRow />
      </SanaForm>
    </div>
  );
};

ReviewForm.propTypes = {
  productId: PropTypes.string.isRequired,
  email: PropTypes.string,
  saved: PropTypes.number,
  sendReview: PropTypes.func.isRequired,
};

export default connectToContext(
  [ProductContext],
  ({ product: { id, parentId, reviews } }) => ({
    saved: reviews.saved,
    productId: parentId ? parentId : id,
  }),
)(connect(mapStateToProps, { sendReview })(ReviewForm));

function mapStateToProps({ user }) {
  const { isAuthenticated, email } = user;
  return { email: isAuthenticated ? email : null };
}

function stopPropagation(e) {
  e.stopPropagation();
}

const useSuccessToast = savedDate => {
  useEffect(() => {
    // Show success toast only if review processed during the last half second.
    if (savedDate && Date.now() < savedDate + 500) {
      toasts.success(<RichText textKey="WriteReview_ReviewSendSuccessfully" disableInsiteEditor />);
    }
  }, [savedDate]);
};
