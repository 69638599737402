import { createContext } from 'react';

const ListContext = createContext({
  variantSelection: null,
  setVariantSelection: (productId, variantId) => null,
  quantities: null,
  updateQuantity: (productId, uomId, variantId, quantity) => null,
  updateVariantQuantity: (productId, uomId, variantId) => null,
  updateVariantQuantities: (productId, uomID, variantQuantityMap) => null,
});

export default ListContext;