export const REQUEST_SAMPLE_PRODUCTS = 'REQUEST_SAMPLE_PRODUCTS';
export const requestSampleProducts = (input: string) => ({
  type: REQUEST_SAMPLE_PRODUCTS,
  payload: { input },
});

export const SAMPLE_PRODUCTS_RECEIVED = 'SAMPLE_PRODUCTS_RECEIVED';
export const sampleProductsReceived = (isSuccess: boolean) => ({
  type: SAMPLE_PRODUCTS_RECEIVED,
  payload: isSuccess,
});

export type RequestSampleProductsActions = ReturnType<
  | typeof requestSampleProducts
  | typeof sampleProductsReceived
>