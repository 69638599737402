import styles from '../PLP.module.scss';
import PropTypes from 'prop-types';
import ProductContext from '../ProductContext';
import Thumbnail from '../Thumbnail';
import Description from '../Description';
import { ProductPrice } from 'components/primitives/product';
import OrderBox from './OrderBox';
import { AbilityTo } from 'behavior/user/constants';
import ProductComparisonSelector from '../ProductComparisonSelector';
import { useResponsiveBreakpoints } from 'utils/layout';
import { connectToContext } from 'utils/react';
import ListContext from './ListContext';
import VariantDropdown from './VariantDropdown';
import { useMemo } from 'react';

const ProductLine = ({ product, selectedVariantId, gridMode, showThumbnail, abilities, index }) => {
  const { xs } = useResponsiveBreakpoints();
  const gridOrSmallScreen = gridMode || xs;
  const { isParent, hasVariants } = product;
  const { price, listPrice } = useMemo(() => getProductPrice(product, selectedVariantId), [product, selectedVariantId]);

  const showVariantDropdown = abilities[AbilityTo.OrderProducts]
    && !isParent
    && hasVariants
    && !!product.variantComponentGroups?.length;

  const contextValue = { ...product, selectedVariantId };
  return (
    <ProductContext.Provider value={contextValue}>
      <div className={styles.item}>
        <div className={styles.tile} role="listitem">
          <i className="visually-hidden">{index + 1}</i>
          {(showThumbnail || gridMode) && <Thumbnail gridMode={gridMode} />}
          <div className={styles.productInfoWrapper}>
            <div className={styles.productInfo}>
              <Description />
              <div className={styles.productAction}>
                {showVariantDropdown && <VariantDropdown product={product} />}
                {price != null && (
                  <div className={styles.prices}>
                    <ProductPrice salesPrice={price} basePrice={listPrice} />
                  </div>
                )}
                {gridOrSmallScreen && (
                  <>
                    {!isParent && <ProductComparisonSelector className={styles.productComparisonSelector} />}
                  </>
                )}
                {abilities[AbilityTo.OrderProducts] && (
                  <OrderBox
                    product={product}
                    className={styles.orderBox}
                    abilities={abilities}
                    gridMode={gridMode}
                  />
                )}
              </div>
            </div>
            {!gridOrSmallScreen && (
              <div className={styles.comparisonCareContainer}>
                {!isParent && <ProductComparisonSelector className={styles.productComparisonSelector} />}
              </div>
            )}
          </div>
        </div>
      </div>
    </ProductContext.Provider>
  );
};

ProductLine.propTypes = {
  product: PropTypes.object.isRequired,
  selectedVariantId: PropTypes.string,
  gridMode: PropTypes.bool,
  showThumbnail: PropTypes.bool,
  abilities: PropTypes.shape({
    [AbilityTo.OrderProducts]: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default connectToContext([ListContext], ({ variantSelection }, { product }) => {
  const selectedVariantId = variantSelection.get(product.id);
  return { selectedVariantId };
})(ProductLine);

function getProductPrice(product, selectedVariantId) {
  if (!selectedVariantId)
    return { price: product.childPrice, listPrice: product.childListPrice };

  const selectedVariant = product.variants.find(v => v.id === selectedVariantId);
  return { price: selectedVariant.childPrice, listPrice: selectedVariant.childListPrice };
}
