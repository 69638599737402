import styles from './Specifications.module.scss';

import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { RowRails } from '../swiper';
import { makeRichText } from 'utils/render';

const GridRow = ({ data, showOnlyDifference }) => {

  let values = data.values;
  if (showOnlyDifference) {
    values = values.map(specifications => (
      specifications.filter(item => (
        !data.values.every(sSpecifications =>
          sSpecifications.some(sItem => sItem === item),
        )
      ))
    ));
  }

  const rows = [];
  const columnsCount = values.length;

  values.forEach((element, columnIndex) => {
    element.forEach((cell, rowIndex) => {
      if (!rows[rowIndex]) {
        rows[rowIndex] = Array(columnsCount);
      }
      rows[rowIndex][columnIndex] = cell;
    });
  });

  return (
    <>
      <div className={styles.fieldName}>
        {data.name}
      </div>
      {rows.map((row, index) => (
        <RowRails items={[...row]} highlightColumns key={index}>
          {value => (
            <div className={styles.fieldValue}>
              {value ? makeRichText(value) :
                (!(index || showOnlyDifference) ? <SimpleText textKey="ProductCompare_EmptySpecification" /> : null)}
            </div>
          )}
        </RowRails>
      ))}
    </>
  );
};

GridRow.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  }).isRequired,
  showOnlyDifference: PropTypes.bool.isRequired,
};

export default GridRow;