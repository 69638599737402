import { EMPTY, merge, of, throwError } from 'rxjs';
import { switchMap, map, catchError, filter, startWith } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  createQuery,
  cookiebarSection,
  mainSection,
  currencyInfoSection,
  addThisSection,
  navigationSection,
  taxDisplaySection,
  shoppingCartSuggestionsSection,
  searchSection,
  documentsSection,
  productComparisonSpecificationsSection,
  productListPresetSection,
  quickOrderSection,
  basketInfoSection,
  checkoutSection,
  cookiebarLinkUrlSection,
  shoppingCartSuggestionsUrlSection,
} from './queries';
import { APP_INIT } from 'behavior/app';
import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'behavior/events';
import { settingsLoaded, setUpdating } from './actions';
import { storage as cookiebarStorage } from 'behavior/cookiebar';

class SectionDescriptor {
  /**
   * @param {object} subQuery part or the setting loading query.
   * @param {Function} [shouldReload] checks whether section should be reloaded. By default - no.
   * @param {Function} [shouldLoad] checks whether section should be loaded. By default - yes.
   */
  constructor(subQuery, shouldReload, shouldLoad) {
    this.subQuery = subQuery;
    this.shouldReload = shouldReload;
    this.shouldLoad = shouldLoad;
  }
}

const languageSpecificSections = [
  new SectionDescriptor(cookiebarLinkUrlSection),
  new SectionDescriptor(shoppingCartSuggestionsUrlSection),
];

const sections = [
  new SectionDescriptor(mainSection),
  new SectionDescriptor(cookiebarSection,
    ({ cookiebar }) => cookiebarStorage.shouldBeShown() && (!cookiebar || cookiebar.isViewerSpecific),
    () => typeof window === 'undefined' || cookiebarStorage.shouldBeShown(),
  ),
  new SectionDescriptor(currencyInfoSection, _ => true),
  new SectionDescriptor(navigationSection),
  new SectionDescriptor(addThisSection),
  new SectionDescriptor(taxDisplaySection, _ => true),
  new SectionDescriptor(shoppingCartSuggestionsSection),
  new SectionDescriptor(searchSection),
  new SectionDescriptor(documentsSection, _ => true),
  new SectionDescriptor(productComparisonSpecificationsSection),
  new SectionDescriptor(productListPresetSection, _ => true),
  new SectionDescriptor(quickOrderSection, _ => true),
  new SectionDescriptor(checkoutSection, _ => true),
  new SectionDescriptor(basketInfoSection, _ => true),
  ...languageSpecificSections,
];

const settingsEpic = (action$, state$, { api }) => {
  const main$ = action$.pipe(
    ofType(APP_INIT, VIEWER_CHANGED),
    switchMap(_ => {
      const query = getQuery(state$);
      if (!query)
        return EMPTY;

      return api.graphApi(query).pipe(
        map(({ settings }) => settingsLoaded(settings)),
        catchError(e => merge(of(settingsLoaded()), throwError(e))),
        startWith(setUpdating()),
      );
    }),
  );

  const languageSpecific$ = action$.pipe(
    ofType(LANGUAGE_CHANGED),
    filter(_ => state$.value.settings.loaded),
    switchMap(_ => {
      const query = createQuery(languageSpecificSections.map(d => d.subQuery));
      
      return api.graphApi(query).pipe(
        map(({ settings }) => settingsLoaded(settings)),
        startWith(setUpdating()),
      );
    }),
  );

  return merge(main$, languageSpecific$);
};

export default settingsEpic;

function getQuery(state$) {
  const settings = state$.value.settings;
  const filtered = settings.loaded
    ? sections.filter(d => d.shouldReload != null && d.shouldReload(settings))
    : sections.filter(d => d.shouldLoad == null || d.shouldLoad());

  return filtered.length ? createQuery(filtered.map(d => d.subQuery)) : null;
}
