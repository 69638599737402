import { createContext } from 'react';

export const contextInitialValue = {
  product: null,
  calculatedInfo: {
    price: null,
    basePrice: null,
    inventory: null,
  },
  pricesInclTax: null,
  uomId: null,
  variantId: null,
  childId: null,
  updateContext: () => null,
};

const ProductContext = createContext(contextInitialValue);

export default ProductContext;