import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ListContext from './ListContext';

const ListProvider = ({ children }) => {
  const [state, setState] = useState(() => ({
    quantities: new Map(),
    quantitiesDependency: {},
  }));

  const [variantSelection, setVariantSelectionState] = useState(new Map());

  const setVariantSelection = useCallback((productId, variantId) => {
    setVariantSelectionState(variantSelection => {
      variantSelection.set(productId, variantId);
      return new Map(variantSelection);
    });
  }, []);

  const quantities = state.quantities;
  const resetQuantities = useCallback(() => setState({
    quantities: new Map(),
    quantitiesDependency: {},
  }), []);

  const updateQuantity = useCallback((productId, uomId, variantId, quantity) => {
    setState(({ quantities }) => {
      if (quantity && quantity.value) {
        let productQuantity = quantities.get(productId);
        if (productQuantity && productQuantity.uomId === uomId && productQuantity.variantId === variantId)
          productQuantity.quantity = quantity;
        else
          productQuantity = { quantity, uomId, variantId };

        quantities.set(productId, productQuantity);
      }
      else
        quantities.delete(productId);

      return { quantities, quantitiesDependency: {} };
    });
  }, []);

  const updateVariantQuantity = useCallback((productId, uomId, variantId) => {
    setState(({ quantities }) => {
      const productQuantity = quantities.get(productId);
      if (productQuantity && productQuantity.uomId === uomId) {
        productQuantity.variantId = variantId;
      }

      return { quantities, quantitiesDependency: {} };
    });
  }, []);

  const updateVariantQuantities = useCallback((productId, uomId, variantQuantityMap) => {
    setState(({ quantities }) => {
      let productQuantities = quantities.get(productId);

      if (!productQuantities) {
        productQuantities = new Map();
        quantities.set(productId, productQuantities);
      }

      if (variantQuantityMap.size > 0) {
        productQuantities.set(uomId, variantQuantityMap);
      } else {
        productQuantities.delete(uomId);

        if (productQuantities.size === 0)
          quantities.delete(productId);
      }

      return { quantities, quantitiesDependency: {} };
    });
  }, []);

  const context = {
    variantSelection,
    setVariantSelection,
    quantities,
    updateQuantity,
    updateVariantQuantity,
    updateVariantQuantities,
    resetQuantities,
  };

  return (
    <ListContext.Provider value={context}>
      {children}
    </ListContext.Provider>
  );
};

ListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ListProvider;