import styles from 'components/objects/product/Details.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Col, Row } from 'components/primitives/grid';
import { useProductContext } from './hooks';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { BasicsCategory } from 'behavior/products/product/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';
import { requestSampleProducts } from 'behavior/products/sampleProductsRequest';
import { toasts } from 'behavior/toasts';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';
import { SanaButton } from 'components/primitives/buttons';
import { usePrintMode } from 'utils/hooks';

const BasicCategoryAction = ({
  abilities: [orderProductsAbility],
  isRequestSampleSuccess,
  isAuthenticated,
}) => {
  const { product, calculatedInfo } = useProductContext();
  const isPrintMode = usePrintMode();
  const dispatch = useDispatch();
  const [successMsgText, unsuccessMsgText] = useSanaTexts(['EmailSuccessfullySent', 'EmailUnsuccessfullySent']).texts;

  useEffect(() => {
    if (isRequestSampleSuccess === undefined)
      return;

    if (isRequestSampleSuccess) {
      toasts.success(makeRichText(successMsgText));
    }

    else {
      toasts.error(makeRichText(unsuccessMsgText));
    }
  }, [isRequestSampleSuccess]);

  if (!product)
    return null;

  const { price, inventory, isOrderable } = calculatedInfo;

  const {
    stockLevels,
    hasVariants,
    isOrderable: productOrderable,
    id: productId,
    parentId,
  } = product;

  const canOrderProducts = orderProductsAbility === AbilityState.Available;
  const isProductBasicCategory = product.categoriesPaths.some(path => path.categories?.some(c => c.id.toUpperCase().includes(BasicsCategory)));

  const shouldShowProductBasicCategory = price !== null
    || (inventory !== null && stockLevels != null)
    || (productOrderable && hasVariants)
    || (canOrderProducts && isOrderable && productOrderable)
    || (canOrderProducts && isOrderable === false);

  if (!shouldShowProductBasicCategory)
    return null;

  return (
    <>
      {isProductBasicCategory && isAuthenticated && !isPrintMode && canOrderProducts &&
        <Row className={`${styles.row} ${styles.basicCategoryRow}`}>
          <Col xs={12} className={styles.basicCategoryColumn}>
            <SanaButton
              disabled={isRequestSampleSuccess}
              textKey="RequestSample"
              type="button"
              className={`${btnStyles.btnAction} ${btnStyles.btnBig}`}
              onClick={() => dispatch(requestSampleProducts({ productId: parentId || productId }))}
            />
          </Col>
        </Row>
      }
    </>
  );
};

BasicCategoryAction.propTypes = {
  abilities: abilitiesPropType,
};

const mapStateToProps = ({
  page: { isRequestSampleSuccess },
  user: { isAuthenticated },
}) => ({
  isRequestSampleSuccess,
  isAuthenticated,
});

export default withAbilities(
  connect(mapStateToProps)(BasicCategoryAction),
  [AbilityTo.OrderProducts],
);
