import styles from './ProductComparisonPage.module.scss';

import { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { useResponsiveBreakpoints } from 'utils/layout';
import BreadcrumbPrimitive from 'components/primitives/breadcrumb';
import { BackTo } from 'components/primitives/links';

const Breadcrumb = ({ routeData, showBreadcrumb, backTo }) => {
  const { xs, sm } = useResponsiveBreakpoints();
  const { texts: [titleText], loaded } = useSanaTexts(['ProductCompare_Header']);

  const backToLink = useMemo(() => {
    if (!backTo)
      return null;

      return <BackTo to={backTo.routeData} url={backTo.url} />;
  }, [backTo]);

  if (!showBreadcrumb || xs || sm)
    return backToLink;

  if (!loaded)
    return null;

  const pageNode = {
    title: titleText,
    link: {
      to: routeData,
      options: {
        backTo,
      },
    },
  };
  return (
    <div className={`${styles.breadcrumbWrapper} d-print-none`}>
      <BreadcrumbPrimitive items={[pageNode]} className={styles.breadcrumb} backLink={backToLink} />
    </div>
  );
};

Breadcrumb.propTypes = {
  routeData: PropTypes.object,
  showBreadcrumb: PropTypes.bool,
  backTo: PropTypes.shape({
    url: PropTypes.string,
    routeData: PropTypes.shape({
      routeName: PropTypes.string.isRequired,
      params: PropTypes.object,
    }).isRequired,
  }),
};

export default connect(state => ({
  routeData: state.routing.routeData,
  showBreadcrumb: state.settings.navigation && state.settings.navigation.breadcrumb,
  backTo: state.page.backTo,
}))(Breadcrumb);